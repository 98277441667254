<template>
  <div id="CompareHeader">
    <div class="row">
      <div class="col-auto">
        <h3>Best match vehicles for you.</h3>
      </div>
      <div class="col-auto pl-0">
        <span id="TotalMatchCount"> Total found: {{ matchCount }}</span>
      </div>
      <div class="col text-right pr-5">
        <span id="ShowHideHeader">
          <a
            id="hide-link"
            class="link-text"
            href=""
            @click.prevent="toggleHeader()"
            >{{
              headerHidden ? "Why did we pick these vehicles?" : "Hide [-]"
            }}</a
          >
        </span>
      </div>
    </div>
    <div class="row" v-show="!headerHidden">
      <div class="col-8">
        <h5>
          Three top-performing vehicles were selected with special emphasis on
          these features:
        </h5>
        <p v-for="(feature, idx) in selectedFeatures" :key="idx">
          <label>{{ feature.name }}: </label>
          {{ feature.description }}
        </p>
      </div>
      <div class="col-4">
        <h5>Our Best Match...</h5>
        <p>
          <label>It's not just about price.</label>
          Our Best Match is a vehicle with a great balance of features, price,
          and a special emphasis on the features most important to you.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { headerHidden: true };
  },
  methods: {
    toggleHeader() {
      this.headerHidden = !this.headerHidden;
    },
  },
  computed: {
    matchCount() {
      return this.$store.state.recommendedVehicles?.length;
    },
    selectedFeatures() {
      return this.$store.getters.selectedFeatureDetails;
    },
  },
};
</script>

<style lang="scss">
#CompareHeader {
  color: $secondary-font-color-light;

  @include media-breakpoint-down(sm) {
    padding-left: 15px;
  }
  h3 {
    color: #8ecfff;
  }
  h3 {
    margin-top: 17px;
    margin-bottom: 0px;
    font-weight: 800;
    font-size: 2rem;

    span {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
  #TotalMatchCount,
  #ShowHideHeader {
    top: 32px;
    position: relative;
    font-size: 0.825rem;
  }
  h5 {
    color: $primary-font-color-light;
  }
  label {
    color: $accent-font-color-2;
  }
  p,
  label {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }
  .link-text {
    color: $link-color-dark;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
